  'use strict'
  
  # ポップアップ非表示の判定
  popAppend = false
  
  # 履歴の追加
  hash = location.hash
  
  if hash isnt '#back'
    setTimeout(() ->
      history.pushState(null,null,location.href)
      history.replaceState(null,null,'#back')
    , 500
    )
  
  # 設定したハッシュタグが消えたら実行
  window.addEventListener('popstate',(e) ->
    if location.hash isnt '#back' and popAppend is false
      $('.p-leave__popup').fadeIn()
      $('body').append($('<div>', {class: 'p-leave__cover-element'}))
      popAppend = true
  
      if popAppend
        $('body').on('click', '.p-leave__popup' , () ->
          deleteElm()
          popAppend = false
        )
  )
  
  # バナー削除
  $('body,.js-close-button').on('click', '.js-close-button', () ->
    deleteElm()
    popAppend = false
  )
  
  deleteElm = () ->
    $('.p-leave__cover-element').fadeOut()
    $('.p-leave__cover-element').remove()
    $('.p-leave__popup').fadeOut()
  
  $('.p-leave__popup a').on('click', (e) ->
    e.stopPropagation()
  )